import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import {CoreBlockFragment} from "../components/blocks/CoreBlock";  // eslint-disable-line

class Post extends Component {
    render() {
      const { name } = this.props.data.wpBrand;
      const products = this.props.data.allWpProduct.nodes;

      this.props.pageContext.pageTitle = name;
      this.props.pageContext.brand = this.props.data.wpBrand;
      this.props.pageContext.products = products;
      this.props.pageContext.productCategories = this.props.data.allWpProductCategory.edges;

      const blocks = buildBlocks( this.props.data.wpPage.blocks, { }, { ...this.props.pageContext } );

      return (
      <Layout
        meta={this.props.data.wpBrand.BrandExtras}
        path={this.props.pageContext.pagePath}
        title={name}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {blocks}
      </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String, $brandSlug: String) {
    wpPage(slug: {eq: $brandSlug}) {
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wpBrand(id: {eq: $id}) {
      id
      name
      description
      BrandExtras {
        fieldGroupName
        image {
          sourceUrl
          id
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 640,
                aspectRatio: 1.78,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF],
                transformOptions: {fit: COVER, cropFocus: CENTER},
                quality: 90
              )
            }
          }
        }
      }
    }
    allWpProductCategory {
      edges {
        node {
          id
          name
          uri
          ProductCategoryExtras {
            brands {
              id
              name
              uri
            }
          }
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
    allWpProduct {
      nodes {
        id
        brands {
          nodes {
            id
            slug
          }
        }
        productCategories {
          nodes {
            name
            slug
            uri
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
}
`;

export default Post
